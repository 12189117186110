.bg-default {
  background-color: #37C837;
}

.color-default {
  color: #37C837;
}

.bg-blue {
  background-color: #37C837;
}

.b-secondary {
  color: #37C837 !important;
  border-bottom: 5px solid #37C837;
}

.borderBottom {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.048);
}

.black-color {
  color: black !important;
}

.bottomShadow {
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.048);
}
.profile{
  width: 150px;
  height: 150px;
  border: 5px solid #37C837;
  border-radius: 75px; 
  border: 2 px solid black;
  background-color: white;
  padding: 5px;

}
.boxProfile{
  position: relative;
  top: -30px;
}

#wrapper {
  overflow-x: hidden;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
  width: 350px;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
      margin-left: 0;
  }

  #page-content-wrapper {
      min-width: 0;
      width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
      margin-left: -15rem;
  }
}